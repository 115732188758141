<template>
	<div class="">
		<nav
			color="primary"
			padless
		>
			<v-row
				justify="center"
				no-gutters
			>
				<v-btn
					v-for="(menuItem , key) in menus" 
					:key="key"
					color="white"
					text
					rounded
					class="my-2 mx-1"
					:to="menuItem.path"
				>
					{{ $t(menuItem.name) }}
				</v-btn>
			</v-row>
		</nav>
	</div>
</template>
 
<script>
	import { mapGetters } from 'vuex';
	export default {
		computed:{
			...mapGetters(["menus"]),
		}
	}
</script>

