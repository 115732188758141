export default {
	"home":"Inicio",
	"userProfile":"Perfil del usuario",
	"shop":"tienda",
	"productDetails": "detalles del producto",
	"cart": "Carrito",
	"checkout":"Revisa",
	"payment":"Pago",
	"fashion":"Moda",
	"gadgets":"Gadgets",
	"accessories":"Accesorios",
	"categories":"Categorías",
	"men":"Hombres",
	"jean":"Vaquero",
	"jackets":"Chaquetas",
	"shirt":"Camisa",
	"tShirt":"Camiseta",
	"women":"Mujer",
	"dress":"Vestido",
	"dresses": "Vestidos",
	"headphone":"Auricular",
	"smartphone":"Smartphone",
	"watch":"Reloj",
	"speaker":"Altavoz",
	"laptopAccessories":"Accesorios para laptop",
	"belts":"Cinturones",
	"jewellery":"Joyería",
	"purse":"Bolso",
	"pages":"Páginas",
	"about":"Acerca de",
	"termAndCondition":"Término y condición",
	"privacyPolicy":"Política de privacidad",
	"blogDetail":"Detalle del blog",
	"faq":"Preguntas más frecuentes",
	"Page":"Página",
	"404Page":'Página 404',
	"session":"Sesión",
	"signIn":"Iniciar sesión",
	"adminSignIn":"Admin login",
	"register":"Registro",
	"forgotPassword":"Se te olvidó tu contraseña",
	"thankYou":"Gracias",
	"contactUs":"Contáctenos",
	"promotions":"Promociones",
	"reservations":"Reservaciones",
	"shops":"Locales",
	"social":"Social",
	"facebook":"Facebook",
	"twitter":"Twitter",
	"youtube":"Youtube",
	"googlePlus":"Google Mas",
	"reports":"Reportes",
	"salesBySeller":"Ventas x Vendedor",
	"dashboard":"Dashboard",
	"management":"Administración",
	"salons":"Salones",
	"tables":"Mesas",
	"waiters":"Mozos",
	"commandZones":"Zonas de Comanda",
	"unitTypes":"Unidades de edida",
	"menu":"Carta",
	"ingredients":"Insumos",
	"recipes":"Recetas",
	"movements":"Movimientos",
	"inventories":"Inventario",
	"inventoryHistories":"Historial de Inventario",
	"delivery":"Delivery",
	"orders":"Pedidos",
	"ordersOnline":"Pedidos online",
	"newOrder":"Nuevo Pedido",
	"list":"Listado",

	"sales":"Ventas",
	"newSale":"Nueva Venta",
	"customers":"Clientes",
	"commands":"Comandas",
	"newCommand":"Nueva Comanda",
	"documents":"Comprobantes",
	"summaries":"Resúmenes",
	"voided":"Anulaciones",
	"inventory":"Inventario",
	"warehouses":"Almacenes",
	"profile":"Perfil",
	"products":"Productos",
	"productAdd":"Producto añadido",
	"goToSite":"Ir al sitio",
	"adminPanel":"Panel de administrador",
	"edit": "editar",
	
	"cash": "Caja",
	"cashManage": "Gestión de caja",
	"openings": "Apertura & cierre",
	"salesReceivables": "Cobrar",
	"openingsHistory": "Historial de caja",

	"configuration": "Configuración",
	"usersManage": "Gestión de usuarios",
	"permissions": "Permisos",
	"roles": "Roles",
	"users": "Usuarios",
	
	"tasks": "Tareas Programadas",
	"correlatives": "Correlativos",
	"establishments": "Establecimientos",
	"configurations": "Configuraciones",
	"distributionAreas": "Zonas de reparto",
	"settings": "Ajustes",
}