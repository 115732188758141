/**
 * VueShop Store
 */

import Vue from 'vue';
import Vuex from 'vuex';

// modules
import auth from './auth';
import ecommerce from './ecommerce';
import location from './location';
import notification from './notification';
import sidebar from './sidebar';
import settings from './settings';

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		auth,
		ecommerce,
		location,
		notification,
		sidebar,
		settings,
	},
});
