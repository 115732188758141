var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"6","sm":"12"}},[_c('el-select',{attrs:{"placeholder":"Select"},on:{"change":_vm.changeClearInput},model:{value:(_vm.search.column),callback:function ($$v) {_vm.$set(_vm.search, "column", $$v)},expression:"search.column"}},_vm._l((_vm.columns),function(label,key){return _c('el-option',{key:key,attrs:{"value":key,"label":label}})}),1)],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"6"}},[(
					_vm.search.column == 'date_of_issue' ||
						_vm.search.column == 'delivery_date' ||
						_vm.search.column == 'date_opening' ||
						_vm.search.column == 'date_of_mov' ||
						_vm.search.column == 'date_of_send'
				)?[_c('el-date-picker',{attrs:{"type":"date","placeholder":"Selecciona","value-format":"yyyy-MM-dd","format":"dd/MM/yyyy","editable":false},on:{"change":_vm.getRecords},model:{value:(_vm.search.value),callback:function ($$v) {_vm.$set(_vm.search, "value", $$v)},expression:"search.value"}})]:[_c('el-input',{attrs:{"placeholder":"Buscar...","prefix-icon":"el-icon-search"},on:{"input":_vm.getRecords},model:{value:(_vm.search.value),callback:function ($$v) {_vm.$set(_vm.search, "value", $$v)},expression:"search.value"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"table-responsive"},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_vm._t("heading")],2),_c('tbody',[_vm._l((_vm.records),function(row,index){return _vm._t("default",null,{"row":row,"index":_vm.customIndex(index)})})],2)]},proxy:true}],null,true)}),_c('div',{staticClass:"mt-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.last_page,"total-visible":7,"prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right"},on:{"next":_vm.getRecords,"previous":_vm.getRecords,"input":_vm.getRecords},model:{value:(_vm.pagination.current_page),callback:function ($$v) {_vm.$set(_vm.pagination, "current_page", $$v)},expression:"pagination.current_page"}})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }