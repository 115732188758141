import AppConfig from 'Constants/AppConfig';
// import router from '../router';
import axios from 'axios';

const api = axios.create({
	baseURL: `${AppConfig.appUrlBackEnd}/api/`,
	headers: {
		'Content-Type': 'application/json',
	},
});

api.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		const accessToken = document.cookie.replace(
			/(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
			'$1'
		);

		if (accessToken) {
			config.headers.common['Authorization'] = `Bearer ${accessToken}`;
		}

		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// api.interceptors.response.use(
// 	() => {
// 		if (response.status === 200 || response.status === 201) {
// 			return Promise.resolve(response);
// 		} else {
// 			return Promise.reject(response);
// 		}
// 	},
// 	(error) => {
// 		if (error.response.status) {
// 			switch (error.response.status) {
// 				case 401:
// 					localStorage.removeItem('access_token');
// 					localStorage.removeItem('user');
// 					router.push({
// 						name: 'login',
// 					});
// 					break;
// 				// case 403:
// 				// 	localStorage.removeItem('access_token');
// 				// 	localStorage.removeItem('user');
// 				// 	router.push({
// 				// 		name: 'login',
// 				// 	});
// 				// 	break;
// 			}
// 			// return Promise.reject(error.response);
// 		}
// 	}
// );

export default api;
