<template>
	<div class="mr-1">
		<v-menu
			transition="scale-transition"
			class="cart"
			min-width="300"
			max-width="300"
			offset-y
			light
		>
			<template v-slot:activator="{ on }">
				<v-btn class="cart-btn" fab dark small outlined color="accent" v-on="on">
					<template v-if="cart && cart.length > 0">
						<v-badge right large color="accent">
							<span slot="badge">{{ cart.length }}</span>
							<v-icon dark>mdi-cart</v-icon>
						</v-badge>
					</template>
					<template v-else>
						<v-icon dark>mdi-cart</v-icon>
					</template>
				</v-btn>
			</template>
			<div v-if="cart && cart.length > 0" class="cart-menu-list white">
				<emb-perfect-scrollbar class="scroll-area" style="max-height:280px">
					<v-list class="cart-list-items py-0" v-for="(cart, index) in cart" :key="index">
						<v-layout row wrap align-center cart-item ma-0>
							<v-flex sm12 md12 lg12 xl12 pa-0>
								<v-layout row wrap align-center class="ma-0">
									<v-flex xs3 sm3 md3 lg3 xl3 pa-0>
										<img :src="cart.item.image" width="60" height="70" />
									</v-flex>
									<v-flex xs9 sm9 md9 lg9 xl9 pa-0>
										<div class="pl-1">
											<p class="word-wrap-break">{{ cart.item.name }}</p>
											<span>
												<emb-currency-sign></emb-currency-sign>
												{{ cart.unit_price * cart.quantity }}
											</span>
										</div>
									</v-flex>
									<v-flex sm4 md4 lg4 xl4 text-center pa-0 cart-action>
										<v-btn
											class="primary d-inline-block"
											icon
											@click.stop="dialog = true"
											@click="deleteProductFromCart(cart)"
										>
											<v-icon>remove_shopping_cart</v-icon>
										</v-btn>
										<v-btn
											class="primary d-inline-block ml-2 d-inline-flex align-items-center"
											icon
											to="/cart"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-list>
				</emb-perfect-scrollbar>
				<emb-delete-confirmation
					ref="deleteConfirmationDialog"
					message="¿Eliminar?"
					@onConfirm="onDeleteProductFromCart"
				>
				</emb-delete-confirmation>
				<v-layout align-center pa-3>
					<v-btn class="primary white--text" block :to="{ name: 'payment' }"
						>Continuar con el pago</v-btn
					>
				</v-layout>
			</div>
			<div v-else class="text-center white pa-6">
				<v-icon size="31" class="accent--text">
					mdi-cart
				</v-icon>
				<h5>Ningún producto encontrado</h5>
			</div>
		</v-menu>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
	components: {
		embPerfectScrollbar: VuePerfectScrollbar,
	},
	data() {
		return {
			selectDeletedProduct: null,
			settings: {
				maxScrollbarLength: 160,
			},
		};
	},
	computed: {
		...mapGetters(['cart', 'selectedCurrency', 'currencies']),
	},
	methods: {
		deleteProductFromCart(product) {
			this.$refs.deleteConfirmationDialog.openDialog();
			this.selectDeletedProduct = product;
		},
		onDeleteProductFromCart() {
			this.$refs.deleteConfirmationDialog.close();

			this.$message.error('Producto Removido del carrito');

			this.$store.dispatch('onDeleteProductFromCart', this.selectDeletedProduct);
		},
	},
};
</script>
