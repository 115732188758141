<template>
	<div>
		<el-dialog
			title="NÚMEROS DE DELIVERY"
			width="60%"
			:visible="showDialog"
			@open="getData"
			:show-close="false"
			center
		>
			<v-row no-gutters align="center" justify="center">
				<v-img src="/static/images/calling.svg" max-width="300"> </v-img>
			</v-row>
			<v-row no-gutters v-if="contact">
				<v-col cols="12" xl="12" class="text-center mt-4">
					<v-card-text>Realize sus pedidos de manera rápida</v-card-text>
					<v-btn large rounded color="accent" :href="`tel:${contact.telephone}`">
						<v-icon small left>mdi-phone</v-icon>
						{{ contact.telephone }}
					</v-btn>
				</v-col>
				<v-col cols="12" xl="12" class="text-center mt-4">
					<v-btn
						large
						rounded
						color="success"
						:href="`https://api.whatsapp.com/send?phone=${contact.whatsapp_number}`"
						target="_new"
					>
						<v-icon small left>mdi-whatsapp</v-icon>
						Pedir por WhatsApp
					</v-btn>
				</v-col>
			</v-row>

			<v-spacer></v-spacer>
			<v-btn color="error" @click="close()" text>
				Cerrar
			</v-btn>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog'],
	data() {
		return {
			resource: '/user/company',
			contact: null,
		};
	},
	methods: {
		getData() {
			if (!this.contact) {
				this.$http.get(`${this.resource}/record`).then((response) => {
					this.contact = response.data.data;
				});
			}
		},
		openDialog() {
			this.open = true;
		},
		close() {
			this.$emit('update:showDialog', false);
		},
	},
};
</script>
