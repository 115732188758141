/**
 * VueShop Global Components
 */
import Header from './components/Layouts/Header/Header.vue';
import FooterV1 from './components/Layouts/Footer/FooterV1.vue';
// import SocialShare from './components/Global/SocialShare';
import CurrencySign from './components/Global/CurrencySign';
import DataTable from './components/Global/DataTable';
import DataTableWithoutFilter from './components/Global/DataTableWithoutFilter';
import DataTableQueryBuilder from './components/Global/DataTableQueryBuilder';
import DeleteConfirmationDialog from './components/Global/DeleteConfirmationDialog';
// import GoogleMap from "./components/Global/GoogleMap";
// import PageTitle from './components/Global/PageTitle';
// import ReviewPopup from './components/Global/ReviewPopup';
import Sidebar from './components/Layouts/Sidebar/Sidebar';
import SidebarPanel from './components/Global/SidebarPanel';
import Call from 'Components/Layouts/Header/Call';
import AppCard from 'Components/AppCard/AppCard';
import LocationDialog from 'Views/User/Location/LocationDialog';
import DeleteConfirmationDialog2 from './components/Global/DeleteConfirmationDialog2';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';


const GlobalComponents = {
   install(Vue) {
      Vue.component('embHeader', Header);
      Vue.component('embFooterV1', FooterV1);
      // Vue.component('embSocialShare', SocialShare);
      Vue.component('embCurrencySign', CurrencySign);
      Vue.component('embDataTable', DataTable);
      Vue.component('embDataTableWithoutFilter', DataTableWithoutFilter);
      Vue.component('embDataTableQueryBuilder', DataTableQueryBuilder);
      Vue.component('embDeleteConfirmation', DeleteConfirmationDialog);
      // Vue.component('embGoogleMap', GoogleMap);
      // Vue.component('embPageTitle', PageTitle);
      // Vue.component('embReviewPopup', ReviewPopup);
      Vue.component('embSidebar', Sidebar);
      Vue.component('embSidebarPanel', SidebarPanel);
      Vue.component('appCard', AppCard);
      Vue.component('embCall', Call);
      Vue.component('embLocationDialog', LocationDialog);
      Vue.component('embDeleteConfirmation2', DeleteConfirmationDialog2);
      Vue.component('embPerfectScrollbar', VuePerfectScrollbar);
   }
}

export default GlobalComponents