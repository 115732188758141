<template>
    <div>
        <v-row>
            <v-col xl="12" lg="12" md="12" sm="12">
                <div class="table-responsive">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <slot name="heading"></slot>
                            </thead>
                            <tbody>
                                <slot v-for="(row, index) in records" :row="row" :index="customIndex(index)"></slot>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div>
                        <v-pagination
                            @next="getRecords"
                            @previous="getRecords"
                            @input="getRecords"
                            :length="pagination.last_page"
                            :total-visible="7"
                            v-model="pagination.current_page"
                            circle
                            prev-icon="mdi-chevron-left"
                            next-icon="mdi-chevron-right"
                        ></v-pagination>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>


<script>

    import queryString from 'query-string'

    export default {
        props: {
            resource: String,
        },
        data () {
            return {
                records: [],
                pagination: {
                    last_page: 1,
                    current_page: 1,
                }
            }
        },
        created() {
            this.$eventHub.$on('reloadData', (resource) => {
                if (this.resource == resource) {
                    this.getRecords()
                }
            })

            this.$eventHub.$on('reloadDataOrders', () => {
                this.getRecords()
            })

            // this.$eventHub.$on('reloadDataSaleReceivables', (resource, reload) => {
            //     if (this.resource == resource && reload == true) {
            //         this.getRecords()
            //     }
            // })
        },
        async mounted () {
            await this.getRecords()

        },
        methods: {
            customIndex(index) {
                return (this.pagination.per_page * (this.pagination.current_page - 1)) + index + 1
            },
            getRecords() {
                return this.$http.get(`/${this.resource}/records?${this.getQueryParameters()}`).then((response) => {

                    this.records = response.data.data
                    this.pagination = {
                        last_page: response.data.last_page,
                        current_page: response.data.current_page,
                        per_page: parseInt(response.data.per_page)
                    }
                });
            },
            getQueryParameters() {
                return queryString.stringify({
                    page: this.pagination.current_page,
                    limit: this.limit
                })
            }
        }
    }
</script>