/* eslint-disable */
// import 'babel-polyfill'
import Vue from 'vue';

import {
	Button,
	Select,
	Option,
	Input,
	Dialog,
	InputNumber,
	Radio,
	Checkbox,
	Message,
	MessageBox,
	DatePicker,
	Alert,
	TimePicker,
	TimeSelect,
	Switch,
	Upload,
	Divider,
	Tooltip,
} from 'element-ui';

Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Input.name, Input);
Vue.component(Dialog.name, Dialog);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Radio.name, Radio);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Message.name, Message);
Vue.component(Alert.name, Alert);
Vue.component(Switch.name, Switch);
Vue.component(Upload.name, Upload);
Vue.component(DatePicker.name, DatePicker);
Vue.component(TimePicker.name, TimePicker);
Vue.component(TimeSelect.name, TimeSelect);
Vue.component(Divider.name, Divider);
Vue.component(Tooltip.name, Tooltip);

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };

import lang from 'element-ui/lib/locale/lang/es';
import VueGoogleCharts from 'vue-google-charts';
import locale from 'element-ui/lib/locale';
import vuetify from '@/plugins/vuetify';
import { store } from './store/store';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import api from 'Api';

import messages from './lang';
import Global from './global';
import router from './router';
import can from './helpers/can';

import './lib/EmbryoCss';

//plugins
locale.use(lang);
Vue.use(VueI18n);
Vue.use(Global);
Vue.use(VueGoogleCharts);

// creating a instance of vue localisation module
const i18n = new VueI18n({
	locale: store.getters.selectedLocale.locale,
	messages,
});

Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue();
Vue.prototype.$http = api;
Vue.prototype.$can = can;

new Vue({
	store,
	router,
	i18n,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
