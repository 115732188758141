<template>
	<div>
		<template v-if="isLoggedIn">
			<v-menu
				transition="scale-transition"
				offset-overflow
				nudge-bottom="30"
				nudge-right="20"
				min-width="200"
				max-width="200"
				class="userblock-dropdown"
				light
			>
				<template v-slot:activator="{ on }">
					<v-btn fab small v-on="on" color="accent">
						<v-icon>mdi-account-circle</v-icon>
					</v-btn>
				</template>
				<v-list class="user-dropdown-list" dense>
					<v-list-item-group color="primary">
						<v-list-item :key="0">
							<v-list-item-icon>
								<v-icon>mdi-account-circle</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Mi Perfil</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :key="1">
							<v-list-item-icon>
								<v-icon>mdi-settings</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Cuenta</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :key="2">
							<v-list-item-icon>
								<v-icon>mdi-view-dashboard</v-icon>
							</v-list-item-icon>
							<v-list-item-content @click="toGoDashboard">
								<v-list-item-title>Ir al panel</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :key="3">
							<v-list-item-icon>
								<v-icon>mdi-power</v-icon>
							</v-list-item-icon>
							<v-list-item-content @click="logout">
								<v-list-item-title>Cerrar sesión</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-menu>
		</template>
		<template v-else>
			<v-btn
				fab
				small
				outlined
				color="accent"
				class="mr-1 hidden-lg-and-up"
				:to="{ name: 'login' }"
			>
				<v-icon dark>mdi-login</v-icon>
			</v-btn>

			<v-btn
				color="accent"
				rounded
				outlined
				class="mr-1 hidden-md-and-down"
				:to="{ name: 'login' }"
				>Ingresar</v-btn
			>
			<v-btn color="accent" rounded @click.prevent="openLocationDialog()">Ordena aquí</v-btn>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			isLoggedIn: 'loggedIn',
		}),
	},
	created() {
		this.checkUserState();
	},
	methods: {
		...mapActions({
			checkUserState: 'setLoggedInState',
		}),
		toGoDashboard() {
			this.$router.push({ name: 'Reports' });
		},
		logout() {
			this.$store.dispatch('logoutUser');

			this.$router.push({ name: 'login' });
		},
		openLocationDialog() {
			this.$eventHub.$emit('openLocationDialog');
		},
	},
};
</script>
