//----------------| Location |------------------//

const state = {
	location: JSON.parse(localStorage.getItem('location')) || null,
};

// getters
const getters = {
	location(state) {
		return state.location;
	},
};

// actions
const actions = {
	saveLocation(ctx, payload) {
		return new Promise((resolve) => {
			ctx.commit('saveLocation', payload);
			resolve(true);
		});
	},
	removeLocation(ctx) {
		return new Promise((resolve) => {
			ctx.commit('onRemoveLocation');
			resolve(true);
		});
	},
};

// mutations
const mutations = {
	saveLocation(state, payload) {
		localStorage.setItem('location', JSON.stringify(payload));
		state.location = payload;
	},
	onRemoveLocation(state) {
		localStorage.removeItem('location');
		state.location = null;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
