<template>
	<div class="sidebar-panel">
		<v-layout justify-end mt-0>
			<v-btn
				class="sidebar-toggle mx-4"
				color="secondary"
				dark
				@click.stop="drawer = !drawer"
			>
				<!-- Mostrar Carrito : <emb-currency-sign></emb-currency-sign> {{getTotalPrice}} -->
				<v-icon dark left small>mdi-cart-outline</v-icon>
				Mostrar carrito
			</v-btn>
		</v-layout>
		<v-navigation-drawer fixed width="340" right v-model="drawer" color="secondary" temporary>
			<div class="side-cart-head accent pa-8 text-center">
				<v-icon class="white--text mb-4">
					mdi-cart-outline
				</v-icon>
				<h5 class="white--text">
					Tienes {{ cart ? cart.length : 0 }} producto(s) en tu carrito
				</h5>
			</div>
			<div class="cart-content">
				<div v-if="cart == ''" class="text-xl-center">
					<v-icon size="26" class="px-2">
						mdi-cart-outline
					</v-icon>
					<h3>No se ha encontrado ningún artículo</h3>
					<v-btn class="accent" to="/">Ir de compras</v-btn>
				</div>
				<div v-else class="cart-shop-list">
					<div class="cart-item p-sm-4 p-3" v-for="(item, index) in cart" :key="index">
						<v-layout row wrap align-center ma-0>
							<v-flex xs3 sm3 md3 lg3 xl3 pa-0>
								<img :src="item.item.image" width="80" height="77" />
							</v-flex>
							<v-flex xs7 sm7 md7 lg7 xl7 cart-product-name pa-0>
								<p class="mb-1 word-wrap-break">{{ item.item.name }}</p>
								<p class="mb-0">{{ item.quantity }}</p>
								<p class="mb-0 font-weight-bold">
									<emb-currency-sign></emb-currency-sign> {{ item.unit_price }}
								</p>
							</v-flex>
							<v-flex xs2 sm2 md2 lg2 xl2 btn-action text-right pa-0>
								<v-btn
									color="error"
									:ripple="false"
									class="ma-0"
									icon
									@click="deleteProductFromCart(item)"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
								<v-btn :ripple="false" color="info" class="ma-0" icon to="/cart">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</div>
					<div class="pt-6 px-4">
						<!-- <div class="layout align-center justify-space-between ma-0">
							<p>Subtotal</p>
							<span><emb-currency-sign></emb-currency-sign> {{itemTotal}}</span>
						</div>
						<div class="layout align-center justify-space-between ma-0">
							<p>Shipping</p>
							<span><emb-currency-sign></emb-currency-sign> {{shipping}}</span>
						</div>
						<div class="layout align-center justify-space-between ma-0">
							<p>Tax(GST)</p>
							<span><emb-currency-sign></emb-currency-sign> {{tax}}</span>
						</div>
						<v-divider class="my-4"></v-divider> -->
						<div class="layout align-center justify-space-between ma-0">
							<h5>Total</h5>
							<h5><emb-currency-sign></emb-currency-sign> {{ getTotalPrice }}</h5>
						</div>
						<v-divider class="my-4"></v-divider>
					</div>
					<emb-delete-confirmation
						ref="deleteConfirmationDialog"
						message="¿Estás seguro de que deseas eliminar este producto?"
						@onConfirm="onDeleteProductFromCart"
					>
					</emb-delete-confirmation>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			selectDeletedProduct: null,
			total: null,
			drawer: null,
			items: [
				{ title: 'Home', icon: 'dashboard' },
				{ title: 'About', icon: 'question_answer' },
			],
		};
	},
	computed: {
		...mapGetters(['cart', 'tax', 'shipping']),
		/**
		 * method for calculatig total of  item
		 */
		itemTotal() {
			let productTotal = 0;
			if (this.cart && this.cart.length > 0) {
				for (const item of this.cart) {
					productTotal += item.total * item.quantity;
				}
				return productTotal.toFixed(2);
			} else {
				return productTotal.toFixed(2);
			}
		},
		/**
		 * method for calculatig subtotal of  item
		 */
		getTotalPrice() {
			let totalPrice = 0;
			if (this.cart && this.cart.length > 0) {
				for (const item of this.cart) {
					totalPrice += item.total * item.quantity;
				}
				return totalPrice.toFixed(2);
			} else {
				return totalPrice.toFixed(2);
			}
		},
	},
	methods: {
		/**
		 * method for deleting product from cart
		 */
		deleteProductFromCart(product) {
			this.$refs.deleteConfirmationDialog.openDialog();
			this.selectDeletedProduct = product;
		},
		/**
		 * method to set cart and show notification after deletion of item
		 */
		onDeleteProductFromCart() {
			this.$refs.deleteConfirmationDialog.close();
			this.$snotify.error('Product Removing from cart', {
				closeOnClick: false,
				pauseOnHover: false,
				timeout: 1000,
			});
			this.$store.dispatch('onDeleteProductFromCart', this.selectDeletedProduct);
		},
	},
};
</script>
