//----------------| Ecommerce Module |------------------//
import { cart, wishlist } from './data';
import router from '../../router';
import dayjs from 'dayjs';

const state = {
	cart,
	wishlist,
	shipping: 0,
	orderData: {
		establishment_id: 1,
		user_id: null,
		order_state_id: '01',
		channel_id: '01',
		prefix: 'PE',
		date_of_issue: dayjs().format('YYYY-MM-DD'),
		currency_type_id: 'PEN',
		payment_method_type_id: 2,
		total: 0,
		items: [],
		shipping: {
			contact: {
				name: null,
				email: null,
				telephone: null,
			},
			reference: null,
			inside_number: null,
			location: {
				address: null,
				latitude: null,
				longitude: null,
			},
		},
		billing: {
			voucher: false,
			document_type_id: null,
			amount: 0,
			customer_number: null,
			customer_name: null,
		},
		type_sale: 'delivery',
		to_go: false,
		notes: null,
	},
};

// getters
const getters = {
	cart: (state) => {
		return state.cart;
	},
	wishlist: (state) => {
		return state.wishlist;
	},
	shipping: (state) => {
		return state.shipping;
	},
	orderData: (state) => {
		return state.orderData;
	},
	cartItem: (state) => {
		return state.cartItem;
	},
	indexOfItem: (state) => {
		return state.indexOfItem;
	},
};

// actions
const actions = {
	addProductToCart(context, payload) {
		context.commit('onAddProductToCart', payload);
	},
	onDeleteProductFromCart(context, payload) {
		context.commit('onDeleteProductFromCart', payload);
	},
	changeQuantityHandler(context, payload) {
		context.commit('onChangeQuantityHandler', payload);
	},
	addItemToWishlist(context, payload) {
		context.commit('onAddItemToWishlist', payload);
	},
	onDeleteProductFromWishlist(context, payload) {
		context.commit('onDeleteProductFromWishlist', payload);
	},
	addAllWishlistItemToCart(context) {
		context.commit('addAllWishlistItemToCart');
	},
	calculateItemTotal(context, payload) {
		context.commit('calculateItemTotal', payload);
		// context.commit('calculateTotal');
	},
	calculateTotal(context) {
		context.commit('calculateTotal');
	},
	makePayment(context, payload) {
		context.commit('makePayment', payload);
	},
	onPrintFinalReceipt(context, payload) {
		context.commit('onPrintFinalReceipt', payload);
	},
	onClearOrderData(context) {
		context.commit('clearOrderData');
	},
};

// mutations
const mutations = {
	onAddProductToCart(state, payload) {
		let newItem = {
			item_id: payload.id,
			category_id: payload.category_id,
			name: payload.name,
			item: payload,
			quantity: 1,
			unit_price: payload.delivery_price,
			unit_type_id: payload.unit_type_id,
			sale_affectation_igv_type_id: payload.sale_affectation_igv_type_id,
			image: payload.image,
			total: payload.delivery_price,
			note: null,
		};

		let cart = localStorage.getItem('cart');

		if (cart) {
			cart = JSON.parse(cart);
			let found = cart.find((x) => x.item_id == newItem.item_id);

			if (!found) {
				cart.push(newItem);
				localStorage.setItem('cart', JSON.stringify(cart));
			}

			state.cart = cart;
		} else {
			let array = [];
			array.push(newItem);
			localStorage.setItem('cart', JSON.stringify(array));

			state.cart = array;
		}
	},
	/**
	 * method for deleting product to cart
	 */
	async onDeleteProductFromCart(state, payload) {
		let index = state.cart.indexOf(payload);
		await state.cart.splice(index, 1);
		await localStorage.setItem('cart', JSON.stringify(state.cart));
	},
	/**
	 * method for change quantity product to cart
	 */
	async onChangeQuantityHandler(state, payload) {
		let index = state.cart.indexOf(payload);
		state.cart[index].quantity = Number(payload.quantity);
		await localStorage.setItem('cart', JSON.stringify(state.cart));
	},

	/**
	 * method for adding item to wishlist
	 */
	onAddItemToWishlist(state, payload) {
		let newItem = {
			id: payload.id,
			image: payload.image,
			name: payload.name,
			currency: payload.currency,
			price: payload.delivery_price,
			quantity: 1,
			total: payload.delivery_price,
		};
		state.wishlist.push(newItem);
	},
	/**
	 * method for deleting item from wishlist
	 */
	onDeleteProductFromWishlist(state, payload) {
		let index = state.wishlist.indexOf(payload);
		state.wishlist.splice(index, 1);
	},

	/**
	 * method for adding all wishlist item to cart
	 */
	addAllWishlistItemToCart(state) {
		if (state.wishlist && state.wishlist.length > 0) {
			for (const wishlistItem of state.wishlist) {
				state.cart.push(wishlistItem);
			}
		}
		state.wishlist = [];
	},

	/**
	 * method to calcualte Order Id , transcation Id, ordered date, delivery date
	 */
	makePayment(state, items) {
		state.orderData.items = items;
		// state.orderData.orderId = '312134645432132132',
		// state.orderData.transactionId = new Date().getTime() - 10000,
		// state.orderData.orderedDate = dayjs().format('MMMM DD, YYYY'),
		// state.orderData.deliveryDate = dayjs().add(5, 'days').format('MMMM DD, YYYY')
		state.cart = [];
		router.push('/checkout/final-receipt');
	},

	/** method to clear Order data after save */
	clearOrderData(state) {
		localStorage.removeItem('cart');
		state.cart = [];
	},

	/** method to init Order data */
	initOrderData() {},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
