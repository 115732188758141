<template>
	<div class="footerV1-wrap section-gap py-5">
		<v-container grid-list-xl py-0>
			<div class="footer-top">
				<v-layout row wrap>
					<v-flex xs12 sm12 md3 lg5 xl12 text-center>
						<div class="about-wrap">
							<h6 class="white--text mb-6">{{title}}</h6>
							<span class="white--text">{{description}}</span>
						</div>
					</v-flex>
				</v-layout>
			</div>
			<hr>
			<div class="footer-bottom">
				<div class="footer-bottom-content px-3">
					<v-layout row wrap align-center justify-space-between>
						<v-flex xs12 sm12 md6 lg6 xl6 class="text-md-left text-center"> 
							<img v-lazyload loading="lazy" :data-src="img" width="218" height="18"/>
						</v-flex>
						<v-flex xs12 sm12 md6 lg6 xl6 py-3 px-2>
							<div class="text-md-right text-center">
								<span class="white--text">{{copyrightText}}</span>
								<a href="https://sislii.com" class="info--text" target="_new"> Sislii</a>
							</div>
						</v-flex>
					</v-layout>
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
	import lazyload from 'Directives/lazyload'
	import AppConfig from "Constants/AppConfig"; 

	export default {
		props:['title', 'description', 'img'],
		data () {
			return{
				about: [
					{
						menuItem:"message.about",
						path:"/about"
					},
					{
						menuItem:"message.termAndCondition",
						path:"/term-condition"
					},
					{
						menuItem:"message.privacyPolicy",
						path:"/privacy-policy"
					},
					{
						menuItem:"message.faq",
						path:"/faq"
					}
				],
				session:[
					{
						menuItem:"message.signIn",
						path:"/login"
					},
					{
						menuItem:"message.register",
						path:'/register',
					},
					// {
					// 	menuItem:"message.forgotPassword",
					// 	path:"/session/forgot-password",
					// },
					{
						menuItem:"message.thankYou",
						path:"/session/thank-you"
					}
				],
				socialLinks:[
					{
						menuItem:"message.facebook",
						path:"https://www.facebook.com/"
					},
					{
						menuItem:"message.twitter",
						path:"https://twitter.com/"
					},
					{
						menuItem:"message.youtube",
						path:"https://www.youtube.com/"
					}

				],
				copyrightText: AppConfig.copyrightText
			}
		},
		directives: {
			lazyload
		},
	}
</script>

