<template>
	<div>
		<v-menu
			transition="scale-transition"
			offset-overflow
			nudge-bottom="30"
			nudge-right="20"
			min-width="200"
			max-width="200"
			class="userblock-dropdown"
			dark
		>
			<template v-slot:activator="{ on }">
				<v-btn fab small v-on="on" color="accent">
					<v-icon>mdi-account-circle</v-icon>
					<!-- <img src="/static/images/user-3.jpg" width="40" height="40" class="v-btn--round"> -->
				</v-btn>
			</template>
			<v-list class="user-dropdown-list" color="secondary" dense>
				<v-list-item-group color="primary">
					<v-list-item :key="0">
						<v-list-item-icon>
							<v-icon>mdi-account-circle</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Mi Perfil</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :key="1">
						<v-list-item-icon>
							<v-icon>mdi-settings</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Cuenta</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :key="2" @click.prevent="deleteCachedData">
						<v-list-item-icon>
							<v-icon>mdi-delete</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Borrar datos</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :key="3">
						<v-list-item-icon>
							<v-icon>mdi-power</v-icon>
						</v-list-item-icon>
						<v-list-item-content @click.prevent="logout">
							<v-list-item-title>Cerrar sesión</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {
		async logout() {
			await this.$store.dispatch('logoutUser')

			location.reload()

			await this.$router.push({ name: 'home' })
		},
		deleteCachedData() {
			localStorage.removeItem('items')
			localStorage.removeItem('salons')
			localStorage.removeItem('categories')
			localStorage.removeItem('favorites')
			location.reload();
		}
	},
};
</script>
