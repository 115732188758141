import en from './en'
import sp from './sp'

export default {
    en: {
        message: en
    },  
    sp: {
        message: sp
    }
}