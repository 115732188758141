export const languages = [
	{
		name: 'Spanish',
		locale: 'sp',
	},
];
export const currencies = [
	{
		id: 0,
		img: '/static/images/india.png',
		title: 'USD',
		symbol: 'S/ ',
	},
	{
		id: 1,
		img: '/static/images/united-states.png',
		title: 'USD',
		symbol: '$',
	},
];
