import api from 'Api';
import router from '../../router';

export default {
	registerUser(ctx, payload) {
		return new Promise((resolve, reject) => {
			api.post('register', payload)
				.then((response) => {
					const token = response.data.data.access_token;

					document.cookie =
						'access_token=' + token + '; max-age=46800';

					const user = JSON.stringify(response.data.data.user);
					localStorage.setItem('user', user);

					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	loginUser(ctx, payload) {
		return new Promise((resolve, reject) => {
			api.post('login', payload)
				.then((response) => {
					if (response.data.access_token) {
						let access_token = response.data.access_token;
						document.cookie =
							'access_token=' + access_token + '; max-age=46800';
						ctx.commit('setLoggedIn', true);
						ctx.dispatch('getLoggedUser').then(() => {
							resolve(response);
						});
					} else {
						reject(response);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	logoutUser(ctx) {
		return new Promise((resolve, reject) => {
			api.post('logout')
				.then(() => {
					document.cookie = 'access_token=; max-age=0';
					ctx.commit('setLoggedIn', false);

					// router.push({
					// 	name: 'login',
					// });

					resolve(true);
				})
				.catch((error) => {
					reject(error);
					resolve(false);
				});
		});
	},
	setLoggedInState(ctx) {
		return new Promise((resolve) => {
			const accessToken = document.cookie.replace(
				/(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
				'$1'
			);

			if (accessToken) {
				ctx.commit('setLoggedIn', true);
				resolve(true);
			} else {
				ctx.commit('setLoggedIn', false);
				resolve(false);
			}
		});
	},
	forgotPassword(ctx, payload) {
		return new Promise((resolve, reject) => {
			api.post('forgot-password', payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	resetPassword(ctx, payload) {
		return new Promise((resolve, reject) => {
			api.post('reset-password', payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getLoggedUserNoRedirect(ctx) {
		return new Promise((resolve, reject) => {
			api.get('logged-in')
				.then((response) => {
					const user = JSON.stringify(response.data);
					localStorage.setItem('user', user);
					ctx.commit('setUserDetails', user);

					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getLoggedUser(ctx) {
		return new Promise((resolve, reject) => {
			api.get('logged-in')
				.then((response) => {
					const user = JSON.stringify(response.data);
					localStorage.setItem('user', user);
					ctx.commit('setUserDetails', user);

					if (user.type == 'user') {
						router.push({ name: 'payment' });
					} else {
						if (router.history.current.name != 'Reports') {
							router.push({ name: 'Reports' });
						}
					}

					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	changePassword(ctx, payload) {
		return new Promise((resolve, reject) => {
			api.post('change-password', payload)
				.then((response) => {
					if (response.data.success) {
						resolve(response);
					} else {
						reject(response);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	verifyEmail(ctx, payload) {
		return new Promise((resolve, reject) => {
			api.get('email-verification', {
				params: payload,
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};
