<template>
	<div class="lang-menu">
		<v-menu dark bottom transition="scale-transition" class="userblock-dropdown" :nudge-width="40"> 
			<template v-slot:activator="{ on }">
				<v-toolbar-title v-on="on">
					<a href="javascript:void(0)" class="white--text">{{
						selectEstablishment.name
					}}</a>
					<v-icon dark> mdi-chevron-down</v-icon>
				</v-toolbar-title>
			</template>
			<v-list color="secondary" class="py-0">
				<v-list-item
					v-for="(row, key) in establishments"
					:key="key"
					@click="changeEstablishment(row.id)"
				>
					<span>{{ row.name }}</span>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import find from 'lodash/find';

export default {
	data() {
		return {
			resource: 'constants',
			selectEstablishment: {},
			establishments: [],
			establishmentId: null,
			form: {
				establishment_id: null,
			},
		};
	},
	created() {
		this.getUserEstablishments();
	},
	computed: {},
	methods: {
		changeEstablishment(id) {
			this.form.establishment_id = id;
			if (this.establishmentId !== id) {
				this.$http
					.post(`/${this.resource}/change-establishment`, this.form)
					.then((response) => {
						if (response.data.success) {
							location.reload();
						} else {
							this.$message.error(response.data.message);
						}
					});
			}
		},
		getUserEstablishments() {
			this.$http.get(`/${this.resource}/establishments`).then((response) => {
				this.establishments = response.data.establishments;
				this.establishmentId = response.data.establishmentId;

				this.selectEstablishment = find(this.establishments, { id: this.establishmentId });
			});
		},
	},
};
</script>
