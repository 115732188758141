<template>
	<div>
		<v-row dense>
			<v-col cols="12" xl="3" lg="6" sm="12">
				<el-select v-model="search.column" placeholder="Select" @change="changeClearInput">
					<el-option
						v-for="(label, key) in columns"
						:key="key"
						:value="key"
						:label="label"
					></el-option>
				</el-select>
			</v-col>
			<v-col cols="12" xl="3" lg="6">
				<template
					v-if="
						search.column == 'date_of_issue' ||
							search.column == 'delivery_date' ||
							search.column == 'date_opening' ||
							search.column == 'date_of_mov' ||
							search.column == 'date_of_send'
					"
				>
					<el-date-picker
						v-model="search.value"
						type="date"
						placeholder="Selecciona"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						:editable="false"
						@change="getRecords"
					>
					</el-date-picker>
				</template>
				<template v-else>
					<el-input
						placeholder="Buscar..."
						v-model="search.value"
						prefix-icon="el-icon-search"
						@input="getRecords"
					>
					</el-input>
				</template>
			</v-col>
		</v-row>

		<v-row>
			<v-col sm="12">
				<div class="table-responsive">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<slot name="heading"></slot>
							</thead>
							<tbody>
								<slot
									v-for="(row, index) in records"
									:row="row"
									:index="customIndex(index)"
								></slot>
							</tbody>
						</template>
					</v-simple-table>
					<div class="mt-3">
						<v-pagination
							@next="getRecords"
							@previous="getRecords"
							@input="getRecords"
							:length="pagination.last_page"
							:total-visible="7"
							v-model="pagination.current_page"
							prev-icon="mdi-chevron-left"
							next-icon="mdi-chevron-right"
						></v-pagination>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import queryString from 'query-string';
import dayjs from 'dayjs';
import head from 'lodash/head';

export default {
	props: ['resource', 'columns'],
	data() {
		return {
			search: {
				column: null,
				value: null,
			},
			// columns: [],
			records: [],
			pagination: {},
			page: 1,
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	created() {
		this.$eventHub.$on('reloadData', (resource) => {
			if (this.resource == resource) {
				this.getRecords();
			}
		});
	},
	async mounted() {
		this.search.column = head(Object.keys(this.columns));
		await this.getRecords();
	},
	methods: {
		customIndex(index) {
			return this.pagination.per_page * (this.pagination.current_page - 1) + index + 1;
		},
		getRecords() {
			return this.$http
				.get(`/${this.resource}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
					this.pagination = response.data.meta;
					this.pagination.per_page = parseInt(response.data.meta.per_page);
					this.page = this.pagination.current_page;
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				page: this.pagination.current_page,
				limit: this.limit,
				...this.search,
			});
		},
		changeClearInput() {
			this.search.value = '';
			this.getRecords();
		},
	},
};
</script>
