//----------------| Notifications |------------------//

const state = {
	notifications: [],
};

// getters
const getters = {
	notifications(state) {
		return state.notifications;
	},
};

// actions
const actions = {
	addNotification(ctx, payload) {
		return new Promise((resolve) => {
			ctx.commit('addNotification', payload);
			resolve(true);
		});
	},
	removeNotification(ctx, payload) {
		return new Promise((resolve) => {
			ctx.commit('removeNotification', payload);
			resolve(true);
		});
	},
};

// mutations
const mutations = {
	addNotification(state, payload) {
		state.notifications.push(payload);
	},
	removeNotification(state, payload) {
		state.notifications.splice(payload, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
