export default {
	"home":"Home",
	"homeOne":"Home One",
	"homeTwo":"Home Two",
	"homeThree":"Home Three",
	"userProfile":"User Profile",
	"shop":"Shop",
	"productDetails": "Product Details",
	"cart": "Cart",
	"checkout":"Checkout",
	"payment":"Payment",
	"fashion":"Fashion",
	"gadgets":"Gadgets",
	"accessories":"Accessories",
	"categories":"Categories",
	"men":"Men",
	"jean":"Jean",
	"jackets":"Jackets",
	"shirt":"Shirt",
	"tShirt":"T-Shirt",
	"women":"Women",
	"dress":"Dress",
	"dresses":"Dresses",
	"headphone":"Headphone",
	"smartphone":"Smartphone",
	"watch":"Watch",
	"speaker":"Speaker",
	"laptopAccessories":"Laptop Accessories",
	"belts":"Belts",
	"jewellery":"Jewellery",
	"purse":"Purse",
	"pages":"Pages",
	"about":"About",
	"termAndCondition":"Term and Condition",
	"privacyPolicy":"Privacy Policy",
	"blogDetail":"Blog Detail",
	"faq":"FAQ",
	"Page":"Page",
	"404Page":'404 Page',
	"session":"Session",
	"signIn":"Sign In",
	"register":"Register",
	"forgotPassword":"Forgot Password",
	"thankYou":"Thank You",
	"contactUs":"Contact Us",
	"social":"Social",
	"facebook":"Facebook",
	"twitter":"Twitter",
	"youtube":"Youtube",
	"googlePlus":"Google Plus",
	"reports":"Reports",
	"invoices":"Invoices",
	"profile":"Profile",
	"products":"Products",
	"productAdd":"Product Add",
	"goToSite":"Go To Site",
	"adminPanel":"Admin Panel",
	"edit":	"Edit"
}