<template>
	<v-app>
		<router-view></router-view>
	</v-app>
</template>

<script>
	export default {
		computed: {
			mobileMenu: {
				get() {
					return this.$store.getters.sidebarOpen;
				},
				set(val) {
					this.$store.dispatch("toggleSidebar", val);
				}
			}
		},
		/**
		 * Method To set the Rtl While page is opened
		*/
		mounted() {
			// 
		},
		methods: {
			/**
			 * Method To Toggle The RTL Layout
			 */
		}
	}
</script>


