export default {
	loggedIn(state) {
		return state.isLoggedIn;
	},
	userDetails(state) {
		return state.userDetails;
	},
	getToken: (state) => {
		return state.token;
	},
};
