<template>
	<el-dialog
		title="Confirma tu ubicación"
		v-model="open"
		width="80%"
		:visible="showLocationDialog"
		@opened="sendLocation"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
		center
	>
		<v-layout>
			<v-flex text-xl-right text-lg-right text-md-center text-sm-center text-xs-center>
				<v-btn
					v-if="!isRouteMenu && address"
					color="accent"
					:to="{ name: 'menu' }"
					class="mb-2 mr-1"
				>
					Continuar con el pedido
				</v-btn>
				<v-btn
					v-if="isRouteMenu && address"
					color="accent"
					@click="close()"
					class="mb-2 mr-1"
				>
					Continuar
				</v-btn>
				<v-btn color="error" @click="close()" class="mb-2">
					Cerrar
				</v-btn>
			</v-flex>
		</v-layout>
		<v-card dark color="secondary" height="600px">
			<v-card-text>
				<v-row dense no-gutters>
					<v-col cols="12" xl="8" lg="10" sm="12" md="12" offset-xl="2" offset-lg="1">
						<div class="form-group map-address map-input-shadow">
							<input
								v-model="address"
								id="autocomplete"
								type="text"
								class="form-control"
								placeholder="Ingresa tu distrito, luego mueve el puntero del mapa"
								autocomplete="off"
								autofocus
								@input="searchAddress"
							/>
						</div>
						<div
							class="suggestion-wrapper"
							v-if="predictions.length > 0 && showSuggestion"
						>
							<div class="suggestion-item xs-p-10">
								<emb-perfect-scrollbar class="scroll-area" style="max-height:280px">
									<v-list rounded dense>
										<v-list-item-group color="primary">
											<v-list-item
												v-for="(item, index) in predictions"
												:key="index"
												@click="getAddressDetails(index)"
												class="suggestion-item"
											>
												<!-- <v-list-item-icon>
                                                    <v-icon>location_on</v-icon>
                                                </v-list-item-icon> -->
												<v-list-item-content>
													<v-list-item-title
														v-text="item.description"
													></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</emb-perfect-scrollbar>
							</div>
						</div>

						<!-- <v-btn color="secondary" outlined rounded large @click.prevent="culqiConfig()">
                                Ordenar ahora
                                <v-icon right>my_location</v-icon>
                            </v-btn>
                        
                            <v-btn color="secondary" outlined rounded large @click.prevent="getAddress">
                                Mi dirección
                            </v-btn> -->
					</v-col>
				</v-row>
				<div class="googlemap" id="googlemap"></div>
			</v-card-text>
		</v-card>
	</el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
	props: ['showLocationDialog', 'isRouteMenu', 'lat', 'lng', 'draggable', 'autoAdd', 'item'],
	data() {
		return {
			open: false,
			latitude: -11.830265,
			longitude: -77.118558,
			resource: 'user/locals',
			predictions: [],
			prediction: {},
			showSuggestion: false,
			isAvailability: false,
			coordinates: [],
			polygon: {},
			defaultCoords: [
				{ lat: -11.81550829492178, lng: -77.13921132206916 },
				{ lat: -11.8151722462836, lng: -77.12410512089728 },
				{ lat: -11.819120791739543, lng: -77.11698117375373 },
				{ lat: -11.821179053496568, lng: -77.10805478215217 },
				{ lat: -11.828571866049314, lng: -77.09947171330451 },
				{ lat: -11.835670458277718, lng: -77.09827008366584 },
				{ lat: -11.844868951553439, lng: -77.09895672917365 },
				{ lat: -11.845666979751881, lng: -77.11371960759162 },
				{ lat: -11.845792983991155, lng: -77.13140072941779 },
				{ lat: -11.838190624211101, lng: -77.14178624272346 },
			],
			address: null,
		};
	},
	methods: {
		...mapActions({
			saveLocation: 'saveLocation',
			removeLocation: 'removeLocation',
		}),
		async sendLocation() {
			await this.getRecord();

			if (this.lat) {
				this.latitude = this.lat;
				this.longitude = this.lng;
				await this.addLocation();
				await this.getAddress();
			}
		},
		async successPosition(position) {
			this.latitude = position.coords.latitude;
			this.longitude = position.coords.longitude;
			// this.sendLocation();
			await this.addLocation();
			await this.getAddress();
			// await this.onSaveLocation()
		},
		failurePosition: function(err) {
			switch (err.code) {
				case 1:
					this.$message.error(
						'Para mejorar la experiencia de usuario, habilita el acceso a su ubicación.'
					);
					break;
				default:
					console.warn('ERROR(' + err.code + '): ' + err.message);
					break;
			}
			this.setDefaultCoords();
		},
		setDefaultCoords() {},
		async getRecord() {
			await this.$http.get(`/${this.resource}/dist-area`).then((response) => {
				if (response.data.data.id) {
					this.coordinates = response.data.data.coordinates;
				} else {
					this.coordinates = this.defaultCoords;
				}
			});
		},
		addLocation() {
			var map = new window.google.maps.Map(document.getElementById('googlemap'), {
				center: new window.google.maps.LatLng(this.latitude, this.longitude),
				fullscreenControl: false,
				streetViewControl: false,
				mapTypeControl: false,
				zoomControl: false,
				zIndex: 0,
				zoom: 16,
			});

			let marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(this.latitude, this.longitude),
				draggable: this.draggable ? true : false,
				clickable: true,
				map: map,
			});

			if (this.draggable) {
				var infowindow = new window.google.maps.InfoWindow();
				infowindow.setContent(`
                        <div class="address-confirmation-container p-5 cond text-center">
                            <p class="font-weight-bold">¿Aquí entregamos tu pedido?</p>
                            <div class="font-weight-bold mb-1">
                                <span class="error--text">(Mueve el ícono de ser necesario)</span>
                            </div>
                        </div>
                    `);
				infowindow.open(map, marker);
			}

			this.polygon = new window.google.maps.Polygon({
				paths: this.coordinates,
				strokeColor: '#1a333c',
				strokeOpacity: 1,
				strokeWeight: 2,
				fillColor: '#1a333c',
				fillOpacity: 0.15,
				editable: false,
				geodesic: false,
			});

			// this.polygon.setMap(map);

			// console.log(this.coordinates);

			// dragend event of marker
			let vm = this;
			window.google.maps.event.addListener(marker, 'dragend', function(e) {
				let position = marker.getPosition();

				vm.latitude = position.lat();
				vm.longitude = position.lng();

				vm.isAvailability = window.google.maps.geometry.poly.containsLocation(
					e.latLng,
					vm.polygon
				)
					? true
					: false;
				console.log(vm.isAvailability);
				if (!vm.isAvailability) {
					vm.removeLocation();
					return vm.$message.error(
						'Lo sentimos! tu ubicación no se encuentra dentro de nuestra zona de cobertura.'
					);
				}

				vm.getAddress();
			});

			// window.google.maps.event.addListener(map, 'click', function(event) {
			//     marker.position = new window.google.maps.LatLng(event.latLng.lat(), event.latLng.lng())
			// });
		},
		async getAddress() {
			const key = 'AIzaSyBQAs4OptqIwf8FSPhei_rsOp4TRMsMf64';
			const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.latitude},${this.longitude}&sensor=true&key=${key}`;

			await axios
				.get(URL)
				.then((response) => {
					this.address = response.data.results[0].formatted_address;
					this.onSaveLocation();
				})
				.catch((error) => {
					console.log(error.message);
				});
		},
		searchAddress() {
			if (this.address.length > 2) {
				let vm = this;
				var displaySuggestions = function(predictions, status) {
					if (status != window.google.maps.places.PlacesServiceStatus.OK) {
						return;
					}

					vm.predictions = predictions;
					vm.showSuggestion = true;
				};

				// Create a new session token.
				var sessionToken = new window.google.maps.places.AutocompleteSessionToken();

				// Pass the token to the autocomplete service.
				var autocompleteService = new window.google.maps.places.AutocompleteService();
				autocompleteService.getPlacePredictions(
					{
						input: this.address,
						sessionToken: sessionToken,
						componentRestrictions: { country: 'PE' },
					},
					displaySuggestions
				);
			}
		},
		async getAddressDetails(index) {
			this.prediction = this.predictions[index];
			this.address = this.prediction.description;
			this.showSuggestion = false;
			// const key = 'AIzaSyDraKM_HmddwYYmjkyNIBup877LuwXTw-xU';
			// const key = 'AIzaSyBQAs4OptqIwf8FSPhei_rsOp4TRMsMf64'; //mio
			const key = 'AIzaSyBQAs4OptqIwf8FSPhei_rsOp4TRMsMf64';
			const URL = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${this.prediction.place_id}&fields=address_component,formatted_address,geometry,vicinity&key=${key}`;

			await axios
				.get(URL)
				.then((response) => {
					this.latitude = response.data.result.geometry.location.lat;
					this.longitude = response.data.result.geometry.location.lng;
					this.addLocation();
					// this.onSaveLocation();
				})
				.catch((error) => {
					console.log(error.message);
				});
		},
		calcDistance(lat1, lng1, lat2, lng2) {
			var rad = function(x) {
				return (x * Math.PI) / 180;
			};

			var radio = 6378.137;
			var dLat = rad(lat2 - lat1);
			var dLon = rad(lng2 - lng1);

			var a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) * Math.cos(rad(lat1)) +
				Math.cos(rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			var d = radio * c;

			return d.toFixed(3);
		},
		openDialog() {
			this.open = true;
		},
		close() {
			if (this.autoAdd) {
				this.$emit('add', this.item);
			}
			this.$emit('update:showLocationDialog', false);
		},
		onSaveLocation() {
			this.saveLocation({
				latitude: this.latitude,
				longitude: this.longitude,
				address: this.address,
			});
		},
	},
};
</script>
