export default (permissions) => {
	let user = JSON.parse(localStorage.getItem('user'));
	const userPermissions = user.permissions;

	// console.log(userPermissions);
	let canEnter = false;

	if (!userPermissions || !permissions) {
		return canEnter;
	}

	if (!Array.isArray(permissions)) {
		canEnter = userPermissions.includes(permissions);
	} else {
		permissions.forEach((permission) => {
			if (userPermissions.includes(permission)) {
				canEnter = true;
			}
		});
	}

	return canEnter;
};
