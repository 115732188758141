import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import AppConfig from 'Constants/AppConfig';
// import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: true,
		themes: {
			light: {
				primary: AppConfig.color.primary,
				accent: AppConfig.color.accent,
				secondary: '#b0bec5',
				error: '#f44336',
				info: '#00D0BD',
				success: '#4CAF50',
			},
			dark: {
				primary: AppConfig.color.primary,
				accent: AppConfig.color.accent,
				secondary: '#212e36',
				error: '#f44336',
				info: '#00D0BD', 
				success: '#4CAF50',
			},
		},
	},
});
